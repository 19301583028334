import React, { Fragment } from 'react'
import { Text } from '../../../components'
import { Image, Header } from 'semantic-ui-react'

export const Overview = () => (
    <Fragment>
        <Header inverted>Character Selection</Header>
        <Image src="/assets/character-selection.png" />
        <br />
        <Text textAlign="left">
            Before starting any matches, you have to choose your team from the selection screen.
            In this section, you can find all the characters available to play and their skills. Just double-click the characters you want to include in your team - once you have selected 3 characters, you can start a match.
            Note that in the future some characters may appear grayed-out and you will not be able to add them to your team until you complete their respective missions.
        </Text>
        <Header inverted>Battles</Header>
        <Image src="/assets/matchup-screen.png" />
        <br />
        <Text textAlign="left">
            There are three different types of games you may play.
            A ladder game is played against a random opponent near you on the ladder.
            This game type is the only way to increase your standing within the ladder.
            A quick game is played against a random opponent.
            This game does not count towards your ladder stats but does towards your missions.
            A private game works just like a quick game except that you must specify the particular opponent you wish to play against and your opponent must do the same.
        </Text>
        <Header inverted>In-Game</Header>
        <Image src="/assets/battle-screen2.png" />
        <Text textAlign="left">
            <br />
            <p>This is where the action happens. Every turn you will receive number of random chakra equal to how many of your ninjas were alive at the end of your last turn. 
            The exception is the first player to take a turn, which will receive only one random chakra. Your chakra pool is located on the top center and can check it anytime of your turn. 
            To understand more about how the chakra engine works, refer to the chakra system section.</p>
            <p> On your turn, you have to decide what skills you may want to use and on what target.
            Each character can use one skill per turn, provided you have sufficient chakra of the appropriate types to pay their costs.
            Any skill that cannot be used that turn for any reason is grayed-out.
            By clicking on a skill, viable targets for that skill with glow yellow and you must then click on one of these viable targets for that skill to be used.
            When a skill has been selected for a character that turn, its icon will display in the gray '?' box. To deselect a skill simply double click its icon in the gray '?' box.</p>
            <p>Skills that affect a specific characters are displayed as small icons next to the character's icon. By hovering over it, you can read how this skill affects the character.</p>
            <p>After you decided what skills to use, click "press when ready". </p>
        </Text>
        <Header inverted>Chakra Selection</Header>
        <Image src="/assets/chakra-selection.png" />
    </Fragment>
)