import React, { Fragment } from 'react'
import { Header, Menu } from 'semantic-ui-react';
import { Text } from '../../../components'
import { Terminology } from './Terminology'
import { Overview } from './Overview'
import { Chakra } from './Chakra'
import { Ranks } from './Ranks'
import { Route, NavLink, RouteComponentProps, Redirect } from 'react-router-dom';

export const Basics = ({ match }: RouteComponentProps ) => {
    return (
    <Fragment>
        <Header inverted as="h1">Game manual</Header>
        <Text textAlign="left">
            <p>
                This section of the game manual is a basic overview of the user interface, game play,
                and standard terminology within the game. Soul-Arena is a turn based strategy game
                where your team of three ninjas is pitted against another player's team of three ninjas
                with the goal of reducing your opposition to 0 health points and winning the match.
            </p>
        </Text>
        <Menu inverted pointing secondary>
          <Menu.Item
            as={NavLink}
            name='overview'
            to={`${match.path}/overview`}
            activeClassName="active"
          />
          <Menu.Item
            as={NavLink}
            name='chakra system'
            to={`${match.path}/chakra`}
            activeClassName="active"
          />
          <Menu.Item
            as={NavLink}
            name='Ranks'
            to={`${match.path}/ranks`}
            activeClassName="active"
          />
          <Menu.Item
            as={NavLink}
            to={`${match.path}/terminology`}
            name='terminology'
            activeClassName="active"
          />
        </Menu>
        <Route
            exact
            path={match.path}
            render={() => <Redirect to={`${match.path}/overview`} />}
        />
        <Route exact path={`${match.path}/overview`} component={Overview} />
        <Route exact path={`${match.path}/chakra`} component={Chakra} />
        <Route exact path={`${match.path}/ranks`} component={Ranks} />
        <Route exact path={`${match.path}/terminology`} component={Terminology} />
    </Fragment>
)}