import React, { Component } from 'react';
import { List, Container, Icon, Segment, Header } from 'semantic-ui-react'
//import { getNews } from '../../services/news'

const style = {
    background: "#f7f7f7",
    height: 500
}

export class News extends Component {
    state = {
        news: [{id_news:2, title:"Snake Pathway Batch + Balance Update 0.1"},{id_news:1,title:"Welcome to Open Alpha!"}]
    }
    async componentDidMount() {
        try {
            //const data = await getNews()
            //this.setState({news: data.res})
        } catch (err) {
            console.error(err)
        }
    }
    render() {
        const { news } = this.state;
        return (
            <div style={style}>
                <br /><br /><br /><br /><br /><br /><br />
                <Header content="Recent News" textAlign='center' />
                <Container text>
                    <Segment>
                        <List>
                            {news.map((item: any) => (
                                <List.Item key={item.id_news} as='a'>
                                    <Icon name='angle double right' />
                                    <List.Content>{item.title}</List.Content>
                            </List.Item>
                            ))}
                        </List>
                    </Segment>

                </Container>
            </div>
        )
    }
}