import React, { Fragment } from 'react'
import { Text } from '../../../components'
import { List, Image } from 'semantic-ui-react'

export const Chakra = () => (
    <Fragment>
        <Text textAlign="left">
            There are 4 types of chakra:
    </Text>
        <Text>
            <List>
                <List.Item>
                    <Image src="/assets/energy_0.gif" verticalAlign='middle' spaced='right' />
                    <List.Content>Taijustu</List.Content>
            </List.Item>
                <List.Item>
                    <Image src="/assets/energy_1.gif" verticalAlign='middle' spaced='right' />
                    <List.Content>Bloodline</List.Content>
            </List.Item>
                <List.Item>
                    <Image src="/assets/energy_2.gif" verticalAlign='middle' spaced='right' />
                    <List.Content>Ninjutsu</List.Content> 
            </List.Item>
                <List.Item>
                    <Image src="/assets/energy_3.gif" verticalAlign='middle' spaced='right' />
                    <List.Content>Genjutsu</List.Content> 
            </List.Item>
            </List>
        </Text>
        <br/>
        <Text textAlign="left">
        The chance to get a certain chakra depends on what chakra types each character uses. This is going to be elaborated further in the future.
        Next to these 4 types, some skills require random chakra <Image src="/assets/energy_random.gif" verticalAlign='middle' />. 
        This kind of chakra can be filled in by any of the types above.
        </Text>
    </Fragment>
)