import React, { Fragment } from 'react'
import { Text } from '../../../components'
import { Table } from 'semantic-ui-react'

export const Ranks = () => (
    <Fragment>
        <Text>
            This is a list of how many experience points you need to level up to each rank.
        </Text>
        <Table celled inverted selectable striped>
            <Table.Header>
                <Table.Row textAlign='center'>
                    <Table.HeaderCell>Rank</Table.HeaderCell>
                    <Table.HeaderCell>Level</Table.HeaderCell>
                    <Table.HeaderCell>Experience</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {RankList.map(({ rank, lvl, xp }) => <Row key={rank} rank={rank} lvl={lvl} xp={xp} />)}
            </Table.Body>
        </Table>
    </Fragment>
)

interface RowProps {
    rank: string,
    lvl: string,
    xp: string
}

const Row = ({ rank, lvl, xp }: RowProps) => (
    <Table.Row textAlign='center'>
        <Table.Cell>
            {rank}
        </Table.Cell>
        <Table.Cell>
            {lvl}
        </Table.Cell>
        <Table.Cell>
            {xp}
        </Table.Cell>
    </Table.Row>
)

const RankList = [
    { rank: "Academy Student", lvl: "1-5", xp: "0-1391" },
    { rank: "Genin", lvl: "5-10", xp: "1392-3486" },
    { rank: "Chunin", lvl: "11-15", xp: "3487-6431" },
    { rank: "Special Jounin", lvl: "16-20", xp: "6432-10226" },
    { rank: "Anbu", lvl: "21-25", xp: "10227-14871" },
    { rank: "Jounin", lvl: "26-30", xp: "14872-20366" },
    { rank: "Sannin", lvl: "31-15", xp: "20367-26711" },
    { rank: "Commander", lvl: "36-40", xp: "26712-33906" },
    { rank: "S-Rank", lvl: "41-45", xp: "33907-41951" },
    { rank: "Village Head", lvl: "46+", xp: "41952+" },
    { rank: "The Tsuchikage", lvl: "46+ and #5", xp: "41952+" },
    { rank: "The Raikage", lvl: "46+ and #4", xp: "41952+" },
    { rank: "The Mizukage", lvl: "46+ and #3", xp: "41952+" },
    { rank: "The Kazekage", lvl: "46+ and #2", xp: "41952+" },
    { rank: "The Hokage", lvl: "46+ and #1", xp: "41952+" },
]