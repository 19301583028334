import React from 'react';
import { Link } from 'react-router-dom';
import { Segment, Container, List } from 'semantic-ui-react';

export const Footer = () => (
    <Segment inverted vertical style={{ padding: '5em 0em', backgroundColor: "#000000" }}>
        <Container textAlign='center'>
            <List horizontal inverted divided link size='small'>
                <List.Item as='a' href='/discord' target="_blank" rel="noopener noreferrer">
                    Contact Us
                </List.Item>
                <List.Item as={Link} to='/legal-disclamer'>
                    Legal Disclamer
                </List.Item>
                <List.Item as='a' href='#'>
                    Privacy Policy
                </List.Item>
            </List>
        </Container>
        <br/>
        <Container textAlign='center'>
            BLEACH © 2001 by Tite Kubo/SHUEISHA Inc. All Rights Reserved.
        </Container>

    </Segment>
)
