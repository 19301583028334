import React, { ReactChild, ReactChildren } from 'react'
import { Image, Menu, Container, Button, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { Media } from './Media'

interface MobileContainerProps {
    children: ReactChild | ReactChildren;
}

export const DesktopContainer = ({ children } : MobileContainerProps) => (
    <Media greaterThan='mobile'>
        <Menu secondary inverted style={{ backgroundColor: "rgba(23, 30, 34, 0.5)" }}>
            <Container>
                <Menu.Item as={Link} to="/">
                    <Image src="/assets/logo.png" size="tiny" />
                </Menu.Item>
                <Menu.Item href="https://arena-game.app" target='_blank'>Main Site</Menu.Item>
                <Menu.Item href="https://boards.arena-game.app" target='_blank'>Boards</Menu.Item>
                <Menu.Item href="https://cc.arena-game.app" target='_blank'>Character Creator</Menu.Item>
            </Container>
            <Menu.Item>
                <div>
                    <Button circular color='grey' as={Link} to="/discord" target="_blank" rel="noopener noreferrer" icon='discord' />
                    <Button circular color='orange' as={Link} to="/patreon"> <Icon name="patreon"/>Support us on Patreon</Button> 
                </div>
            </Menu.Item>
            <Menu.Item>
                <Button as={Link} to="/download" color="teal" floated="right">Play now</Button>
            </Menu.Item>
        </Menu>
        {children}
    </Media>
)