import React, { Fragment, useState } from 'react';
import { Header, List, Grid, Input } from 'semantic-ui-react';
import { Text } from '../../../components'
import styled from 'styled-components';

export const Terminology = () => {
    const [value, setValue] = useState('');
    const [term, changeTerms] = useState(terms)
    const handleChange = (e: any, props: any) => {
        setValue(props.value);
        const filtered = terms.filter(e => e.title.toLowerCase().includes(props.value.toLowerCase()));
        changeTerms(filtered)
    }
    return (
        <Fragment>
            <Grid>
                <Grid.Column>
                    <Input value={value} placeholder='Search' size="mini" style={{ float: 'right' }} icon="search" onChange={handleChange} />
                </Grid.Column>
            </Grid>
            {value.length === 0 ? <Text textAlign="left">
                <Header inverted as="h3" textAlign="left">Common Terms in a Skill's description</Header>
                <TermList items={common} name="common" />
                <Header inverted as="h3" textAlign="left">Skill Class Definitions</Header>
                <p>
                    Classes are used to further describe a skill beyond its description.
                    Generally Skill Classes are only important in determining whether one skill
                    will affect another in the course of a game.
            </p>
                <Header inverted as="h4" textAlign="left">Distance</Header>
                <TermList items={distance} name="distance" />
                <Header inverted as="h4" textAlign="left">Skill Type</Header>
                <TermList items={skill} name="skill" />
                <Header inverted as="h4" textAlign="left">Persistence Type</Header>
                <TermList items={persistent} name="persisnt" />
            </Text> :
                <Text textAlign="left">
                    <TermList items={term} name="all" />
                </Text>
            }
        </Fragment>
    )
}

const Bold = styled.span`
    color: #fdfcfa;
`;

interface Item {
    title: string;
    description: string;
}

interface Items {
    items: Item[],
    name: string
}
const TermList = ({ items, name }: Items) => (
    <List bulleted key={name}>
        {items.map(item =>
            <List.Item key={item.title}>
                <Bold>{item.title} :</Bold> {item.description}
            </List.Item>
        )}
    </List>
)

const common = [
    { title: "Damage", description: "This should be rather self explanatory. If not, find a Webster's dictionary. Damage is done by percentage or a fixed amount." },
    { title: "Piercing Damage", description: "This type of damage will ignore damage reduction." },
    { title: "Affliction Damage", description: "This type of damage will ignore damage reduction and destructible defense." },
    { title: "Increased Damage", description: "Some skills increase the damage done or taken from other skills. This amount is usually a percentage or fixed amount." },
    { title: "Damage Reduction", description: "This means that any damage done to a character will be reduced by either a certain percentage or fixed amount after all damage done that turn is calculated. For example 50% reduction from 120 damage total from all skills is 60 damage, where as 50 points reduction from 120 damage total from all skills is 70 damage." },
    { title: "Stun", description: "This means a character is unable to use a skill for the duration that the stun is in effect." },
    { title: "Invulnerable", description: "This means a character will not be a valid target for enemy skills (by default), though they may still be affected by some skills already affecting them." },
    { title: "Remove Chakra, Lose Chakra", description: "This means that your opponent will have a certain fixed amount of random (any available type) or specific chakra removed from their chakra pool. If a skill removes specific chakra and your opponent does not have any of that type then no chakra is removed." },
    { title: "Steal Chakra", description: "This works exactly like removing chakra except that chakra is instead put into your chakra pool rather than removed from the game." },
    { title: "Reflect", description: "This will return the effects of a skill back upon the user that targeted the character using a reflecting type skill. Note that Control type stun skills will end up canceling themselves and Action skills will still be activated on the reflecting character, but will have its effects be reflected only this turn." },
    { title: "Heal", description: "This means a certain amount of health is returned to the character, percentage or fixed. Characters that have reached 0 health cannot be healed, also characters cannot be healed above 100 health. Healing does not affect destructible defense." },
    { title: "Counter", description: "This skill will negate another incoming skill. It will not negate a skill already affecting a character. Action skills can only be countered on the first turn. Counter type skill are generally linked with another type of effect that will affect the character who's skill was negated." },
    { title: "Remove 'xxx'", description: "Any skill that states it removes another type of effect (typically be class type) will completely negate that effect on a character." },
    { title: "Ignore 'xxx'", description: "As the word states, a skill with this aspect will ignore some other type of effect, the ignored effect is not negated or removed, it just doesn't do anything. This is a little different for skills that ignore invulnerability. Those skills are able to target invulnerable characters even when that character is not unable to become invulnerable." },
    { title: "Destructible Defense", description: "This skill gives a character a pool of health that must first be depleted before the character will take damage. A damage only skill that does not actually damage the character's actual health is not considered to have affected the character." },
    { title: "Copy", description: "This type of skill will copy another skill that is used, replacing an existing skill with the new copied skill for the duration of the copy. Skills that require an other skill to be performed before they can be used, cannot be copied." },
    { title: "Invisible", description: "Your opponent will not see any icons for these skills during the time it is in effect. For some skills only the target is not known but the fact that the skill is in use is known. When an invisible skill ends, The opposing team will always be informed that the skill was used." },
    { title: "Increasing or decreasing duration", description: "When you increase or decrease the duration of a skill or effect, all related effects will also be modified accordingly. By related effects are meant, all effects that are linked with eachother by a 'during this time' sentence." },
]
const distance = [
    { title: "Melee", description: "The skill is carried out a close range to the target and user. Abbreviated as 'Me' sometimes." },
    { title: "Ranged", description: "The skill is carried out far away from the user. Abbreviated as 'Ra' sometimes." }
]
const persistent = [
    { title: "Instant", description: "Instant skills are skills that do their work instantly, or are placed on the target on the first turn and have no connection to the caster for the rest of its duration." },
    { title: "Action", description: "Action skills are skills that last multiple turns and require the attention of the caster each turn anew. If the caster loses contact with the target, by a stun or an invulnerability, the action will have no effect. But since the skill is done each turn anew, the skill will continue once the caster has contact with its target again." },
    { title: "Control", description: "Controls require constant contact between caster and target. If the contact is broken, the skill will end. Controls are also only cast once, only at the start of the skill when they make contact with the target, much like instants." }
]
const skill = [
    { title: "Physical", description: "Skills that involve brute force and melee attacks, these skills deal physical damage." },
    { title: "Chakra", description: "Skills that involve energy abilities, these skill deal chakra damage." },
]

const terms = [...common, ...skill, ...persistent, ...distance]
