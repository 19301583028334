import { useState, useEffect } from 'react';

export const useSystem = () => {
    const [system, setSystem ] = useState<any>({});
    useEffect(() => {
        setSystem(detectUA(navigator.platform, navigator.userAgent))
    }, [])
    return system;
}

const detectUA = (platform: string, ua: string) => {
    if (/Mac/.test(platform)) {
        return { os: 'apple', url: "https://get.arena-game.app/mac_sa"  };
    } else if (/Win/.test(platform)) {
        if (/x64/.test(ua)) {
            return { os: 'windows', arm: 64, url: "https://get.arena-game.app/win64_sa" };
        } else {
            return { os: 'windows', arm: 32, url: "https://get.arena-game.app/win32" };
        }
    } else if (/Android/.test(ua)) {
        return { os: 'android', arm: 32, url: "https://get.arena-game.app/android_sa" };
    } else if (/Lin/.test(platform)) {
        if (/x86_64/.test(platform)) {
            return { os: 'linux', arm: 64, url: "https://get.arena-game.app/lin64_sa" };
        } else {
            return { os: 'linux', arm: 32, url: "https://get.arena-game.app/lin32_sa"};
        }
    } else {
        return {}
    }
}