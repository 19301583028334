import React, { Fragment, useEffect, useState } from 'react'
import { Button, Icon, Grid } from 'semantic-ui-react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
/* import { FeaturedVideo } from '../components/Video/FeaturedVideo' */
import { useSystem } from '../hooks/useSystem';
// @ts-ignore 
import SnowStorm from 'react-snowstorm';



export const Home = () => {
    const system = useSystem()
    const [isXmas, setXmas] = useState(false);
    useEffect(()=> {
        const mnth = new Date().getMonth()
        if(mnth === 11 || mnth === 0) {
            setXmas(true)
        }
    }, [])
    return (
        <Fragment>
            {isXmas && <SnowStorm />}
            <Carousel autoPlay interval={5000} infiniteLoop showThumbs={false}>
                <div>
                    <img alt="" src="/assets/battle-screen-beta.png" />
                    <p className="legend">Welcome to Soul Arena Next Generation!</p>
                </div>
                <div>
                    <img alt="" src="assets/matchup-screen-beta.png" />
                    <p className="legend">We've got a new fresh look</p>
                </div>
                <div>
                    <img alt="" src="assets/character-selection-beta.png" />
                    <p className="legend">And plenty of characters you may be familiar with</p>
                </div>
            </Carousel>
            <br />
            <Grid columns={2} stackable>
                <Grid.Row>
                    <Grid.Column>
                    {system.url && <Button inverted icon basic labelPosition='left' size="huge" as="a" href={system.url} download="na">
                            <Icon name={system.os} />
                                    Download Beta
                        </Button>}
                    </Grid.Column>
                    <Grid.Column>
                    <Button inverted icon basic labelPosition='left' size="huge" as="a" href="/discord" target="_blank" rel="noopener noreferrer">
                            <Icon name="discord" />
                                    Join our Discord
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <br />
            {/* <FeaturedVideo /> */}
        </Fragment>
    )
}