import React, { Fragment } from 'react';
import { Header, Button, Icon } from 'semantic-ui-react';
import { useSystem } from '../hooks/useSystem';
import { Text } from '../components'

const DiscordLink = () => <a href="/discord" target="_blank" rel="noopener noreferrer">discord</a>

export const Download = () => {
    const system = useSystem()
    return (
        <Fragment>
            <Header inverted as="h1">Get started now!</Header>
            <Text colorStrengh>
                <p>Soul Arena Next Generation is a multiplatform app available for Windows, Linux, Mac and Android.</p>
                <p>You have to download the app to be able to play it. Keep in mind
                that the game is currently in beta,
                so please report any bug you may encounter on our <DiscordLink />!
             </p>
            </Text>
            <br />
            <br />

            {system.url ?
                <Button inverted icon basic labelPosition='left' size="huge" as="a" href={system.url} download="na">
                    <Icon name={system.os} />
                        Download
                        Soul-Arena
                        Beta
                </Button> :
                <Text colorStrengh>Your OS is not supported yet. Check out our <DiscordLink /> for more info.</Text>
            }

        </Fragment>
    )
}